import { CSSObject } from "@emotion/react";
import { Colors } from "../../ui-library/design-tokens/colors";
import { Fonts } from "../../ui-library/design-tokens/fonts";
import { grid } from "../../ui-library/design-tokens/grid";
import { Device } from "../../ui-library/design-tokens/media-queries";

const root: CSSObject = {
  [Device.Desktop]: {
    display: "flex",
    flexDirection: "row-reverse",
    paddingTop: grid(12),
  },
};

const pageContent: CSSObject = {
  textAlign: "center",
};

const heading: CSSObject = {
  fontSize: 100,
  lineHeight: "90px",
  fontWeight: Fonts.Weight.Bold,
  letterSpacing: 20,
  marginBottom: grid(2),
  [Device.DesktopTablet]: {
    fontSize: 150,
    lineHeight: "120px",
    marginBottom: grid(3.5),
  },
};

const description: CSSObject = {
  fontSize: 24,
  color: Colors.MidGrey,
  marginBottom: grid(3),
  [Device.DesktopTablet]: {
    fontSize: 36,
    marginBottom: grid(4),
  },
};

const hiddenMobile: CSSObject = {
  [Device.TabletMobile]: {
    display: "none",
  },
};

const hiddenDesktop: CSSObject = {
  [Device.Desktop]: {
    display: "none",
  },
};

const videoContainer: CSSObject = {
  [Device.Desktop]: {
    flex: 1,
    marginRight: grid(6),
  },
};

const video: CSSObject = {
  position: "relative",
  paddingBottom: "56%",
  marginBottom: grid(3),
  overflow: "hidden",

  "> iframe": {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100% !important",
    height: "100% !important",
  },
};

const styles = {
  root,
  heading,
  description,
  hiddenMobile,
  hiddenDesktop,
  pageContent,
  videoContainer,
  video,
};

export default styles;
