import * as React from "react";
import { EventCategory } from "../../../utils/analytics";
import PillButton from "../../ui-library/buttons/pill-button";
import Container from "../../ui-library/container/container";
import styles from "./not-found.styles";

const NotFound: React.FC = () => {
  const returnToHome = (
    <PillButton
      to="/"
      name="Return to the moon"
      color="blue"
      css={{ width: "100%" }}
      eventCategory={EventCategory.NotFoundPage}
    >
      Return to the moon
    </PillButton>
  );

  return (
    <Container css={styles.root}>
      <div css={styles.pageContent}>
        <h1 css={styles.heading}>404</h1>
        <p css={styles.description}>PAGE NOT FOUND</p>

        <div css={styles.hiddenMobile}>{returnToHome}</div>
      </div>

      <div css={styles.videoContainer}>
        <div css={styles.video}>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/KTf5j9LDObk"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      <div css={styles.hiddenDesktop}>{returnToHome}</div>
    </Container>
  );
};

export default NotFound;
